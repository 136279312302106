import React from 'react';
import {
    HTMLElement,
    TextNode,
    Node,
    parse,
} from 'node-html-better-parser';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    ISelectedOption,
} from 'graphql/customer/customerOrdersInterfaces';
import embStyles from '../style.css';

export interface IEmbroideryFlagsParams {
    options: ISelectedOption[];
}

interface IParsedNode extends HTMLElement {
    valid: boolean;
}

interface IImgAttributes {
    alt: string;
    src: string;
    style: string;
}

interface IImage extends IImgAttributes {
    number: number;
}

interface IFlagImage extends IImage {
    position: string;
}

export const EmbroideryFlags: React.FC<IEmbroideryFlagsParams> = (
    {
        options,
    },
) => {
    const t = usePhraseTranslater();

    if (!options || !options.length) {
        return null;
    }

    const findImageNode = (childNodes: Node[]): Node|undefined => childNodes.find((childNode) => {
        const node = childNode as HTMLElement;
        if (!node.tagName) {
            return false;
        }

        return node.tagName === 'img';
    });

    const findFlagNumberNode = (childNodes: Node[]): Node|undefined => childNodes.find((childNode) => {
        const node = childNode as TextNode;
        if (!node.value) {
            return false;
        }

        return node.value.match(/Flag ([\d+])/gi);
    });

    const findPositionNode = (childNodes: Node[]): Node|undefined => childNodes.find((childNode) => {
        const node = childNode as TextNode;
        if (!node.value) {
            return false;
        }

        return node.value.match(/Position: [\w+]/gi);
    });

    const getImageFromNode = (imageNode: Node, imageNumber?: number): IImage => {
        const { attributes } = imageNode as HTMLElement;
        const imageAttributes: IImgAttributes = {
            alt: attributes?.alt || '',
            src: attributes?.src || '',
            style: attributes?.style || '',
        };
        const image: IImage = {
            number: imageNumber || 1,
            ...imageAttributes,
        };
        return image;
    };

    const getFlagNumberFromNode = (numberNode: Node): number => {
        const currentNumberNode = numberNode ? numberNode as TextNode : { value: '' };
        const numberString = currentNumberNode.value.replace(/[^0-9]/gi, '');
        return parseInt(numberString, 10);
    };

    const getPositionFromNode = (positionNode: Node): string => {
        const node = positionNode as TextNode;
        if (!node.value) {
            return '';
        }

        return node.value.replace(/Position: /gi, '');
    };

    const getFlagImages = (flagsHtml: string): IFlagImage[] => {
        const flags: IParsedNode = parse(flagsHtml);

        if (flags.children.length < 1) {
            return [];
        }

        if (flags.children.length === 1) {
            const imageNode = findImageNode(flags.childNodes);
            if (!imageNode) {
                return [];
            }

            const image: IImage = getImageFromNode(imageNode);
            const flagImage: IFlagImage = {
                position: t('Both Collars'),
                ...image,
            };
            return [flagImage];
        }

        const positions: string[] = [];
        const images: IImage[] = [];
        flags.children.forEach((child) => {
            const imageNode = findImageNode(child.childNodes);
            if (imageNode) {
                const foundNumberNode = findFlagNumberNode(child.childNodes);
                const number = foundNumberNode ? getFlagNumberFromNode(foundNumberNode) : 0;
                const image: IImage = getImageFromNode(imageNode, number);
                images.push(image);
            }

            const positionNode = findPositionNode(child.childNodes);
            if (positionNode) {
                const positionValue = getPositionFromNode(positionNode);
                positions.push(positionValue);
            }
        });

        const flagImages: IFlagImage[] = images.map((image: IImage, index: number) => {
            const flagImage: IFlagImage = {
                position: positions[index] || t('Not Found'),
                ...image,
            };
            return flagImage;
        });

        return flagImages;
    };

    return (
        <div className={classes(embStyles.embOptions, embStyles.flags)}>
            <ul className={embStyles.embOptionsList}>
                {options.map(
                    (flagOption: ISelectedOption) => {
                        const flagImages = getFlagImages(flagOption.value);
                        const flagCount = flagImages.length;
                        return (
                            <li className={embStyles.embOption}>
                                <div className={embStyles.embOptionText}>
                                    <strong className={embStyles.embOptionTextLabel}>
                                        {t('Collar:')}
                                    </strong>
                                    <span className={embStyles.embOptionTextValue}>
                                        {(flagCount > 1 ? t('Flags') : t('Flag'))}
                                    </span>
                                </div>
                                <div className={embStyles.flagImagesWrapper}>
                                    {flagImages.map(
                                        (flagImage: IFlagImage) => (
                                            <div className={embStyles.flagWrapper}>
                                                <img
                                                    className={embStyles.flagImage}
                                                    src={flagImage.src}
                                                    alt={flagImage.alt}
                                                    data-test-flag-number={flagImage.number}
                                                />
                                                <span className={embStyles.flagPosition}>
                                                    {t('Position: %1', flagImage.position)}
                                                </span>
                                            </div>
                                        ),
                                    )}
                                </div>
                            </li>
                        );
                    },
                )}
            </ul>
        </div>
    );
};
