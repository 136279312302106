import React, { useEffect, useRef, useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IOrderItem, ISelectedOption } from 'graphql/customer/customerOrdersInterfaces';
import { ProductImage } from 'ui/component/sales-components/product-image';
import { SelectedOptions } from 'ui/component/sales-components/selected-options';
import { LinkPopup } from 'ui/component/custom/link-popup';
import { EmbroideryText } from './emb-text';
import { EmbroideryLogos } from './emb-logos';
import { EmbroideryFlags } from './emb-flags';
import { EmbroideryInstructions } from './emb-instructions';
import styles from './style.css';

export interface IEmbDetailsParams {
    parentItem: IOrderItem;
    embroideryItems: IOrderItem[];
    label: string;
    beforeLabel?: string;
    setWrapperHasZIndex?: (hasZIndex: boolean) => void;
}

export const EmbroideryDetails: React.FC<IEmbDetailsParams> = (
    {
        parentItem,
        embroideryItems,
        label,
        beforeLabel,
        setWrapperHasZIndex,
    },
) => {
    const [detailsOpen, setDetailsOpen] = useState(false);

    const t = usePhraseTranslater();

    const embroideredDetailsRef = useRef<HTMLDivElement>(null);

    const {
        product_image_url: productImageUrl,
        product_name: productName,
        quantity_ordered: qtyOrdered,
        product_sku: productSku,
        selected_options: selectedOptions,
    } = parentItem;

    const productSkuOption: ISelectedOption = {
        label: t('Item'),
        value: productSku,
    };

    const productQtyOption: ISelectedOption = {
        label: t('Qty'),
        value: `${qtyOrdered}`,
    };

    const isText = (name: string): boolean => (name === 'Embroidered Text');
    const isLogo = (name: string): boolean => (name === 'Embroidered Logos');
    const isFlag = (name: string): boolean => (name === 'Embroidered Flags');
    const isSP = (name: string): boolean => (name === 'Special Instructions');

    useEffect(() => {
        if (setWrapperHasZIndex) {
            setWrapperHasZIndex(detailsOpen);
        }
    }, [detailsOpen]);

    if (!embroideryItems || !embroideryItems.length) {
        return null;
    }

    return (
        <div ref={embroideredDetailsRef}>
            <LinkPopup
                linkLabel={label}
                beforeLabel={beforeLabel}
                wrapperClassName={styles.embroideredDetails}
                linkClassName={styles.embOpenDetailsBtn}
                contentClassName={styles.embroideryItems}
                dataTest="action-btn-toggle-details"
                isPopupOpen={detailsOpen}
                setIsPopupOpen={setDetailsOpen}
                calculateTop
            >
                <div className={styles.embItemsWrapper}>
                    <div className={styles.embHeader}>
                        <span>{t('Embroidery Details')}</span>
                    </div>
                    <div className={styles.embProduct}>
                        <div className={styles.left}>
                            <div className={styles.productName}>
                                <span className={styles.text}>{productName}</span>
                            </div>
                            <div className={styles.productDetails}>
                                <SelectedOptions
                                    selectedOptions={[productSkuOption]}
                                    hasWrapper={false}
                                    optionClassName={styles.detailOption}
                                    labelClassName={styles.detailOptionLabel}
                                />
                                <SelectedOptions
                                    selectedOptions={selectedOptions}
                                    hasWrapper={false}
                                    optionClassName={styles.detailOption}
                                    labelClassName={styles.detailOptionLabel}
                                />
                                <SelectedOptions
                                    selectedOptions={[productQtyOption]}
                                    hasWrapper={false}
                                    optionClassName={styles.detailOption}
                                    labelClassName={styles.detailOptionLabel}
                                />
                            </div>
                        </div>
                        <div className={styles.right}>
                            <ProductImage
                                className={styles.productImage}
                                productImageUrl={productImageUrl}
                                productName={productName}
                                width={100}
                                height={150}
                            />
                        </div>
                    </div>
                    <div className={styles.embItems}>
                        {embroideryItems.map(
                            (embItem: IOrderItem) => {
                                const {
                                    product_name: name,
                                    selected_options: options,
                                } = embItem;
                                return (
                                    <div className={styles.embItem}>
                                        {isText(name) && (<EmbroideryText options={options} />)}
                                        {isLogo(name) && (<EmbroideryLogos options={options} />)}
                                        {isFlag(name) && (<EmbroideryFlags options={options} />)}
                                        {isSP(name) && (<EmbroideryInstructions options={options} />)}
                                    </div>
                                );
                            },
                        )}
                    </div>
                </div>
            </LinkPopup>
        </div>
    );
};
