import React from 'react';
import DOMPurify from 'dompurify';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    ISelectedOption,
} from 'graphql/customer/customerOrdersInterfaces';
import embStyles from '../style.css';

interface IEmbLogo {
    label: string;
    placement: string;
    image: string;
}

export interface IEmbroideryInstructionsParams {
    options: ISelectedOption[];
}

export const EmbroideryLogos: React.FC<IEmbroideryInstructionsParams> = (
    {
        options,
    },
) => {
    const t = usePhraseTranslater();

    if (!options || !options.length) {
        return null;
    }

    const logoImgOptions: ISelectedOption[] = options.filter(
        (option: ISelectedOption) => option.value.startsWith('<img'),
    );

    const placementRegex = /Logo [\d+] Placement/gi;
    const logoPlacementOptions: ISelectedOption[] = options.filter(
        (option: ISelectedOption) => option.label.match(placementRegex) !== null,
    );

    const logos: IEmbLogo[] = [];
    logoImgOptions.forEach((logoItem: ISelectedOption) => {
        const logoNumberString = logoItem.label.replace(/[^0-9]/g, '');
        const logoNumber = parseInt(logoNumberString, 10);
        const placementIndex = logoNumber - 1;
        logos.push({
            label: `Logo ${logoNumber}`,
            placement: logoPlacementOptions[placementIndex]?.value ?? t('No Placement'),
            image: logoItem.value ?? '',
        });
    });

    const processHtml = (html: string): { __html: string } => ({ __html: DOMPurify.sanitize(html) });

    return (
        <div className={classes(embStyles.embOptions, embStyles.logos)}>
            <ul className={embStyles.embOptionsList}>
                {logos.map(
                    (logo: IEmbLogo) => {
                        const { label, placement, image } = logo;
                        return (
                            <li className={embStyles.embOption}>
                                <div className={embStyles.embOptionText}>
                                    <strong className={embStyles.embOptionTextLabel}>{`${label}:`}</strong>
                                    <span className={embStyles.embOptionTextValue}>{placement}</span>
                                </div>
                                <div className={embStyles.logoImageWrapper}>
                                    {/* eslint-disable-next-line react/no-danger */}
                                    <div className={embStyles.logo} dangerouslySetInnerHTML={processHtml(image)} />
                                </div>
                            </li>
                        );
                    },
                )}
            </ul>
        </div>
    );
};
