import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { ISelectedOption } from 'graphql/customer/customerOrdersInterfaces';
import styles from './style.css';

interface ISelectedOptionsProps {
    selectedOptions: ISelectedOption[];
    hasWrapper?: boolean;
    className?: string;
    optionClassName?: string;
    labelClassName?: string;
}

export const SelectedOptions: React.FC<ISelectedOptionsProps> = (
    {
        selectedOptions,
        hasWrapper = true,
        className,
        optionClassName,
        labelClassName,
    },
) => {
    if (!selectedOptions.length) {
        return null;
    }

    const SelectedOptions = () => (
        <>
            {selectedOptions.map(
                (option: ISelectedOption) => (
                    <div className={classes(styles.selectedOption, (optionClassName || ''))}>
                        <span className={classes(styles.optionLabel, (labelClassName || ''))}>
                            {`${option.label}:`}
                        </span>
                        <span className={styles.optionValue}>{option.value}</span>
                    </div>
                ),
            )}
        </>
    );

    if (!hasWrapper) {
        return (<SelectedOptions />);
    }

    return (
        <div className={classes(styles.selectedOptions, (className || ''))}>
            <SelectedOptions />
        </div>
    );
};
