import React from 'react';
import { Link } from 'ui/component/custom/link';
import styles from './style.css';

interface IProductLinkProps {
    productName: string;
    productUrl: string;
    targetBlank?: boolean;
    forceUnderline?: boolean;
    underlineOnHover?: boolean;
    opacityOnHover?: boolean;
}

export const ProductLink: React.FC<IProductLinkProps> = (
    {
        productName,
        productUrl,
        targetBlank = false,
        forceUnderline = false,
        underlineOnHover = false,
        opacityOnHover = false,
    },
) => (
    <Link
        label={productName}
        href={productUrl}
        linkClassName={styles.productLink}
        noOriginalLinkClassName
        target={targetBlank ? '_blank' : undefined}
        forceUnderline={forceUnderline}
        underlineOnHover={underlineOnHover}
        opacityOnHover={opacityOnHover}
    />
);
