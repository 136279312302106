import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { IOrderItem, TypeSplitOrderItem } from 'graphql/customer/customerOrdersInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { SelectedOptions } from '../selected-options';
import { SummaryLine } from '../summary-line';
import { ProductLink } from './product-link';
import styles from './style.css';

interface IProductDetailsProps {
    config: ISalesLayoutConfig;
    item: IOrderItem|TypeSplitOrderItem;
    className?: string;
    isSplitItem?: boolean;
    productNameAsLink?: boolean;
    showPriceTotal?: boolean;
    priceTotalLabel?: string;
    useQty?: number;
    usePrice?: number;
    children?: JSX.Element;
}

export const ProductDetails: React.FC<IProductDetailsProps> = (
    {
        item,
        className,
        isSplitItem = false,
        productNameAsLink = true,
        showPriceTotal = false,
        priceTotalLabel,
        useQty,
        usePrice,
        children,
    },
) => {
    const t = usePhraseTranslater();

    const {
        product_sku: productSku,
        product_url: productUrl,
        product_name: productName,
        selected_options: selectedOptions,
        product_sale_price: productSalePrice,
        quantity_ordered: orderedQty,
        parentBundleItem,
    } = item;

    const hasParentBundle = !!parentBundleItem;
    const parentBundleSku = `${parentBundleItem?.product_sku}`;
    const finalProductUrl = parentBundleItem?.product_url || productUrl;
    const finalProductName = parentBundleItem?.product_name || productName;

    const splitOrderItem = item as TypeSplitOrderItem;
    const { finalQuantity } = splitOrderItem;

    const { value: price } = productSalePrice;
    const qty = isSplitItem ? finalQuantity : orderedQty;

    const calcQty = useQty !== undefined ? useQty : qty;
    const calcPrice = usePrice !== undefined ? usePrice : price;

    const totalPrice = (calcPrice * calcQty);

    return (
        <div
            className={classes(styles.itemDetails, (className || ''), {
                [styles.showPriceTotal]: showPriceTotal,
            })}
        >
            <div className={classes(styles.itemDetail, styles.productName)}>
                {productNameAsLink && (
                    <ProductLink
                        productName={finalProductName}
                        productUrl={finalProductUrl}
                        forceUnderline
                        opacityOnHover
                    />
                )}
                {!productNameAsLink && (
                    <span className={classes(styles.link, styles.text)}>{productName}</span>
                )}
            </div>
            {hasParentBundle && (
                <div className={classes(styles.itemDetail, styles.productSku)}>
                    <span>{t('Parent Bundle Item# %1', parentBundleSku)}</span>
                </div>
            )}
            <div className={classes(styles.itemDetail, styles.productSku)}>
                <span>{t('Item# %1', productSku)}</span>
            </div>
            <SelectedOptions
                selectedOptions={selectedOptions}
                className={styles.productSelectedOptions}
            />
            <SummaryLine
                amount={calcPrice}
                label={showPriceTotal ? t('Price:') : undefined}
                lineClassName={classes(styles.itemDetail, styles.productPrice)}
                noOriginalLineClassName
            />
            {showPriceTotal && (
                <SummaryLine
                    amount={totalPrice}
                    label={priceTotalLabel || t('Total:')}
                    lineClassName={classes(styles.itemDetail, styles.productPrice)}
                    noOriginalLineClassName
                />
            )}
            {children && (
                <div className={classes(styles.itemDetail, styles.children)}>
                    {children}
                </div>
            )}
        </div>
    );
};
