import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import {
    ISelectedOption,
} from 'graphql/customer/customerOrdersInterfaces';
import embStyles from '../style.css';

export interface IEmbroideryInstructionsParams {
    options: ISelectedOption[];
}

export const EmbroideryInstructions: React.FC<IEmbroideryInstructionsParams> = (
    {
        options,
    },
) => {
    if (!options || !options.length) {
        return null;
    }

    return (
        <div className={classes(embStyles.embOptions, embStyles.specialInstructions)}>
            <ul className={embStyles.embOptionsList}>
                {options.map(
                    (specialInstructionsOption: ISelectedOption) => (
                        <li className={embStyles.embOption}>
                            <div className={embStyles.embOptionText}>
                                <strong className={embStyles.embOptionTextLabel}>
                                    {`${specialInstructionsOption.label}:`}
                                </strong>
                                <span className={embStyles.embOptionTextValue}>
                                    {specialInstructionsOption.value}
                                </span>
                            </div>
                        </li>
                    ),
                )}
            </ul>
        </div>
    );
};
