import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import {
    ISelectedOption,
} from 'graphql/customer/customerOrdersInterfaces';
import embStyles from '../style.css';

export interface IEmbroideryTextParams {
    options: ISelectedOption[];
}

export const EmbroideryText: React.FC<IEmbroideryTextParams> = (
    {
        options,
    },
) => {
    if (!options || !options.length) {
        return null;
    }

    const textLineOptions: ISelectedOption[] = options.filter(
        (option: ISelectedOption) => option.label.startsWith('Embroidery (line'),
    );

    const textStyleOptions: ISelectedOption[] = options.filter(
        (option: ISelectedOption) => option.label === 'Embroidery Style',
    );

    const textColorOptions: ISelectedOption[] = options.filter(
        (option: ISelectedOption) => option.label === 'Embroidery Color',
    );

    const textPlacementOptions: ISelectedOption[] = options.filter(
        (option: ISelectedOption) => option.label === 'Embroidery Placement',
    );

    const getFontFamily = () => {
        if (textStyleOptions.length > 0 && textStyleOptions[0].value) {
            return {
                fontFamily: textStyleOptions[0].value,
            };
        }

        return {};
    };

    const getColor = () => {
        if (textColorOptions.length > 0 && textColorOptions[0].value) {
            return {
                color: textColorOptions[0].value,
            };
        }

        return {};
    };

    return (
        <div className={classes(embStyles.embOptions, embStyles.textLines)}>
            <ul className={embStyles.embOptionsList}>
                {textLineOptions.map(
                    (textLineOption: ISelectedOption) => (
                        <li className={embStyles.embOption}>
                            <div className={embStyles.embOptionText}>
                                <strong className={embStyles.embOptionTextLabel}>
                                    {`${textLineOption.label}:`}
                                </strong>
                                <span
                                    className={embStyles.embOptionTextValue}
                                    style={{
                                        ...getFontFamily(),
                                        ...getColor(),
                                    }}
                                >
                                    {textLineOption.value}
                                </span>
                            </div>
                        </li>
                    ),
                )}
                {textPlacementOptions.map(
                    (textPlacementOption: ISelectedOption) => (
                        <li className={embStyles.embOption}>
                            <div className={embStyles.embOptionText}>
                                <strong className={embStyles.embOptionTextLabel}>
                                    {`${textPlacementOption.label}:`}
                                </strong>
                                <span className={embStyles.embOptionTextValue}>
                                    {textPlacementOption.value}
                                </span>
                            </div>
                        </li>
                    ),
                )}
            </ul>
        </div>
    );
};
